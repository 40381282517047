import React from 'react';

import {Image} from 'react-bootstrap';
import {useRouter} from 'next/router';
import useRoutes from '../../../routing/utils/useRoutes';
import useTranslations from '../../../utils/hooks/useTranslations';
import getSportybeConfig from "../../../../sportybe.config";
import RouteLink from "../../app/components/RouteLink";
import {Route} from "react-router";

interface ComponentProps {
  showIcon?: boolean;
}

const Promo: React.FC<ComponentProps> = ({ showIcon = true }) => {
  const router = useRouter();
  const routes = useRoutes();
  const {apps} = getSportybeConfig();

  return <div className="footer-promo">
        <div className="container">
          {showIcon && (
            <a onClick={() => router.push(routes.index.url())} className="logo justify-content-center">
              <Image src="/icons/logoFooter.svg" className={'image img-fluid'} alt={'SportyBe logo'} loading={'lazy'} />
            </a>
          )}
          <h1>Buď v obraze. Buď SportyBe</h1>
          <div className="applicationGroup">
            <div className="applicationWrap">
                <RouteLink target={"_blank"} to={apps.ios}  external={true} className="application application__ios">
                  SportyBe pro iOS
                </RouteLink>
                <RouteLink target={"_blank"} to={apps.android}  external={true} className="application application__android">
                 SportyBe pro Android
                </RouteLink>
            </div>
          </div>
        </div>
      </div>

};

export default Promo;
