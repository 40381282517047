import React, {useState} from 'react';
import useTranslations from '../../../utils/hooks/useTranslations';
import {useUser} from '../../../utils/hooks/useUser';
import RouteLink from '../../app/components/RouteLink';
import DynamicModals from '../../ui/dynamicModals';
import {useUserQuery} from '../queries';
import useRoutes from "../../../routing/utils/useRoutes";
import HamburgerButton from "./button/hamburgerButton";

const LoginOrInfo: React.FC = () => {
  const {ts} = useTranslations();
  const routes = useRoutes();
  const {isLogged} = useUser();
  const {data: userInfo} = useUserQuery();
  const [showLoginModal, setShowLoginModal] = useState(false);


  if (!isLogged) {
    return <>
      <div className="mobile-show mobile-login-wrapper" key={new Date().getTime()}>
        <RouteLink className="button-default button-navigation button-registration" to={routes.login.url()}/>
        <HamburgerButton isLogged={isLogged}/>
      </div>
      <div className={'mobile-hidden'}>
        <div className={'displayFlex login-wrapper'}>
          <RouteLink className={'displayFlex flexCenter flexColumn navigation-login'}
                     onClick={() => setShowLoginModal(true)}>
            {ts('common:login')}
          </RouteLink>
          <RouteLink className={'button-default button-navigation button-registration'}
                     to={routes.register.url()}>
            {ts('common:register')}
          </RouteLink>
        </div>
      </div>
      {showLoginModal && (
        <DynamicModals.LoginModal
          open={showLoginModal}
          hideModal={() => setShowLoginModal(false)}
        />
      )}
    </>

  }

  return <div className={'userData'} key={new Date().getTime()}>
    <div className="about--header displayFlex">
      <RouteLink className={"avatar"} to={routes.accountReservations.url()}>
        <div className={"str"}>{userInfo?.name?.split(" ")[0][0]}{userInfo?.name?.split(" ")[1][0]}</div>
      </RouteLink>
      <div className={'horizontaly-center'}>
        <div className={'displayFlex'}>
          <RouteLink className="name cursor-pointer mobile-hidden"
                     to={routes.accountReservations.url()}>
            {userInfo?.name}
          </RouteLink>
          <HamburgerButton isLogged={isLogged}/>
        </div>
      </div>
    </div>
  </div>
};

export default LoginOrInfo;
