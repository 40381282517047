import {useQuery} from 'react-query';
import {call} from '../../../common/ServerSideHttpClient';
import API_CONFIG from '../../../lib/api/config';
import {useCookies} from 'react-cookie';
import {Cookie} from '../../../types';
import jwt_decode from 'jwt-decode';
import {Token} from '../models';

export interface UserQueryData {
    customerId?: string | null
    name?: string | null,
    email?: string | null,
    phone?: string | null,
    avatar?: string | null
    nickname?: string | null
    isActive?: boolean | null,
    virtualWallet?: {
        virtualWalletId: string,
        amount: number
    } | null
}


export const getUserQueryKey = () => 'user';

export const getUser = async (accountId: any): Promise<UserQueryData> => {

    if (accountId === 'anonymous') {
        return {};
    }

    return await call<any>(API_CONFIG.GET_ACCOUNT_ABOUT(accountId));
};

export const useUserQuery = () => {

    const [cookies] = useCookies();
    const token = cookies[Cookie.JWT_COOKIE];
    const jwtData: null | Token = token == null ? null : jwt_decode(cookies[Cookie.JWT_COOKIE]);

    return useQuery(getUserQueryKey(), () => getUser(jwtData?.sub),
        jwtData?.sub === 'anonymous' ? {cacheTime: 10} : {}
    );
};
