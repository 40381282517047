//TODO REMOVE THIS!!!
export async function call<T>(request: RequestInfo, method: string = 'GET', payload: any = null): Promise<Nullable<T>> {
  const response = await fetch(request, {
    method, // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json',
      'X-Real-IP': '127.0.0.1',
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: payload ? JSON.stringify(payload) : null, // body data type must match "Content-Type" header
  });

  if (response.status != 200) {
    return null;
  }

  const body = await response.json();

  if (body.success) {
    return body.result;
  }

  return null;
}
