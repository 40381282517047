import React, {useRef, useState} from 'react';
import RouteLink from "../../../app/components/RouteLink";
import useRoutes from "../../../../routing/utils/useRoutes";
import useTranslations from "../../../../utils/hooks/useTranslations";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {useCookies} from "react-cookie";
import getConfig from "next/config";
import {Cookie} from "../../../../types";
import {useOutsideAlerter} from "../../../../utils/hooks/useOutsideAlerter";

export interface ComponentProps {
  isLogged: boolean
}

const HamburgerButton: React.FC<ComponentProps> = ({isLogged = false}) => {
  const [isToggled, setIsToggled] = useState<boolean>(false);
  const routes = useRoutes();
  const {ts} = useTranslations();
  const {isMobile} = useWindowDimensions();
  const [, , removeCookie] = useCookies();
  const {publicRuntimeConfig} = getConfig();
  const ref = useRef(null);
  const {outsideAlerter} = useOutsideAlerter();
  outsideAlerter(ref, () => setIsToggled(false));
  const logout = () => {
    removeCookie(Cookie.JWT_COOKIE, {
      path: '/',
      domain: `.${publicRuntimeConfig.domain}`
    });
    window.location.reload();
  }

  return <>
    {!isLogged || isMobile ?
      <RouteLink className={`button-default button-navigation button-hamburger ${isLogged && 'is-loggedHamburger'}`}
                 onClick={() => setIsToggled(!isToggled)}>
        <img src={'/icons/hamburger.svg'}/>
      </RouteLink>
      :
      <RouteLink className={'navigation-login'} onClick={() => setIsToggled(!isToggled)}>Nastavení</RouteLink>
    }
    {isToggled && (
      <div className={'navigation-dropdown'} ref={ref}>
        <ul className="nav navbar-nav">
          <li className="">
            <RouteLink to={routes.articles.url()}>{ts('navigation:blog')}</RouteLink>
          </li>
          <li>
            <RouteLink to={routes.applications.url()}>{ts('navigation:apps')}</RouteLink>
          </li>
          <li>
            <RouteLink to={routes.forSportground.url()}>{ts('navigation:forSportgrounds')}</RouteLink>
          </li>
          <li>
            {isLogged ? (
              <RouteLink
                to={routes.accountBaseSettings.url()}>{'Nastavení'}</RouteLink>
            ) : (
              <RouteLink to={routes.login.url()}>{ts('navigation:login')}</RouteLink>
            )}
          </li>
          <li>
            <RouteLink to={routes.faq.url()}>{ts('navigation:aboutSportybe.faq')}</RouteLink>
          </li>
          {/*<li>*/}
          {/*  <RouteLink to={routes.guaranteedReservation.url()}>*/}
          {/*    {ts('navigation:aboutSportybe.guaranteedReservation')}*/}
          {/*  </RouteLink>*/}
          {/*</li>*/}
          {isLogged && <li>
            <RouteLink onClick={() => logout()}>
              {'Odhlásit se'}
            </RouteLink>
          </li>}
        </ul>
      </div>
    )}
  </>;
};

export default HamburgerButton;
