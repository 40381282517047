import {useCookies} from 'react-cookie';
import {Cookie} from '../../types';
import jwt_decode from 'jwt-decode';
import {Token} from '../../modules/common/models';

export const useUser = () => {
    const [cookies] = useCookies();
    const token = cookies[Cookie.JWT_COOKIE];
    const jwtData: null | Token = token == null ? null : jwt_decode(cookies[Cookie.JWT_COOKIE]);

    const id: string | null | undefined = jwtData?.sub === 'anonymous' ? null : jwtData?.sub;
    const isLogged: boolean = jwtData ? jwtData?.sub != undefined && jwtData?.sub != 'anonymous' : false;
    const roles: string[] = jwtData === null ? [] : jwtData.roles;

    return {
        userId: id,
        isLogged,
        roles,
    };
};
