const RELEASE_ID = process.env.BUILD_COMMIT || 'dev_release_id';
const isProduction = process.env.NODE_ENV === 'production';

const getSportybeConfig = () => {

    return {
        isProduction,
        releaseId: RELEASE_ID,
        apiURL: process.env.API_URL || '/services/portal-api/api/v1',
        fapiURL: process.env.FAPI_URL ||'/services/frontend-api/api/v1',
        // cdnUrl: process.env.CDN_URL || 'https://backoffice.sportybe.etyka.cz/',
        nodeEnv: process.env.NODE_ENV  || 'dev',
        domain: process.env.APP_DOMAIN  || 'localhost',
        disableCache: process.env.DISABLE_CACHE === '1',
        runtimeEnv: process.env.RUNTIME_ENV || process.env.NODE_ENV|| 'dev',
        parameters: {
            phone: '+420 736 102 601',
            email: 'info@sportybe.com',
            instagram: 'https://instagram.com/',
            facebook: 'https://facebook.com/',
            twitter: 'https://twitter.com/',
        },
        apps: {
          ios: "https://apps.apple.com/cz/app/sportybe-com-sportuj-s-n%C3%A1mi/id1582721840",
          android: "https://play.google.com/store/apps/details?id=com.sportybe.app&hl=en_US"
        },
        serverRuntimeConfig: {
            port: process.env.PORT || 3000,
        },
        services: {
            securityService: {
                host: process.env.SECURITY_URL || '/services/security/api/v1',
                path: '/services/security/api/v1'
            },
            portalApiService: {
                host: process.env.API_URL,
                path: '/services/portal-api/api/v1'
            },
            frontendApiService: {
                host: process.env.API_URL,
                path: '/services/frontend-api/api/v1'
            },
        }
    }
};

module.exports = getSportybeConfig;
